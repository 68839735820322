import React from "react"
import "./style.css"
import "../utils/utilStyle.css"
import { Portfolio } from "../../../types/graphql-types"

type Props = {
  item: Portfolio
  isEn?: boolean
}
const PortfolioItem: React.FC<Props> = props => {
  return (
    <div className={"investment-item-wrapper"}>
      <div
        className={"image-wrapper"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <img
          src={props.item.image?.url || ""}
          style={{ width: "100%", objectFit: "contain" }}
          className={"image"}
        />
      </div>
      <div className={"textField-div"}>
        <div className={"util-title"}>
          <p>{props.item.title}</p>
        </div>
        <div className={props.isEn ? "detail-wrapper" : "util-body"}>
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: `${props.item.body}`
              }}
            />
          </p>
        </div>
      </div>
    </div>
  )
}
export default PortfolioItem
