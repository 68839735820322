import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Query } from "../../types/graphql-types"
import PortfolioItem from "../components/Portfolio/PortfolioItem"
import PageTitle from "../components/utils/PageTitle"
import { ja_description } from "../components/const"

const Portfolio: React.FC<PageProps<Query>> = ({ data, location }) => {
  return (
    <Layout selectPageName="Portfolio" location={location}>
      <SEO title="Portfolio" description={ja_description} lang={"ja"} />
      {data.microcmsPortfolio?.title && (
        <PageTitle
          title={data.microcmsPortfolio.title}
          key={data.microcmsPortfolio.id}
        />
      )}
      <div className={"portfolio-contents-wrapper"}>
        <div className={"investments-wrapper"}>
          {data.microcmsPortfolio &&
            data.microcmsPortfolio.portfolio?.map(
              item =>
                item && (
                  <PortfolioItem key={item.title} item={item} isEn={false} />
                )
            )}
        </div>
      </div>
    </Layout>
  )
}
export default Portfolio

export const PortfolioIndexQuery = graphql`
  query PortfolioIndexQuery {
    microcmsPortfolio {
      portfolio {
        title
        body
        image {
          url
        }
      }
      title
    }
  }
`
